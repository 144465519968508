import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const ConsentForm = ({
  openConsentDialog,
  cancelConsentAccept,
  isConsentAccepted,
  confirmConsentAccept,
}) => {
  return (
    <Dialog open={openConsentDialog} onClose={cancelConsentAccept}>
      <DialogTitle>
        <b>
          Assignment of Benefits / Financial Responsibility / Telehealth Consent
        </b>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          I acknowledge the payment and insurance information set forth below
          and agree to pay for services rendered to me and/or facilitate the
          payment for services rendered to me by the providers affiliated with
          any of the behavioral health groups managed by Detalks (Practice)
          Payment of Fees: I agree to pay for charges for services as described
          in this agreement. I understand that: Payment for sessions with
          providers affiliated with Practice is payable online through debit or
          credit card or ACH transfer, unless otherwise established Payment for
          sessions is due after each session unless otherwise agreed upon and
          Practice will charge my card or bank account for my responsibility.
          Receipts may be provided at the time of the charge or monthly I will
          be charged for sessions that I do not keep, unless I provide enough
          notice to the provider affiliated with the Practice (your treating
          provider will tell you how much notice is required to avoid being
          charged for sessions you do not keep) I understand that I cannot
          submit bills for cancellations to my insurance company or managed care
          plan Insurance and Managed Care Plans: Practice participates in a
          number of insurance and managed care plans. If Practice participates
          in my plan, I agree to pay all applicable deductibles, co-payments,
          co-insurances and any other form of cost-sharing. If my insurance
          benefits run out, Practice will inform me of the ending date, and I
          will then be responsible for all charges dating from the end of
          insurance coverage. If my insurance plan denies the visit despite
          Practice following necessary procedures, I understand I may be
          responsible to pay in full for the service. Assignment of Insurance
          Fees; Release of confidentiality for authorization of benefits and for
          clinical care: I agree to allow my insurance plan or managed care plan
          to pay Practice directly, instead of paying me. In the event that my
          plan pays me directly, I will promptly turn the payment over to
          Practice unless I have already paid the charges myself. I authorize
          Practice to provide my insurance plan or managed care plan any
          information reasonably required to obtain insurance benefits and
          authorization for services. I authorize Practice to obtain at any time
          during my treatment here, any and all relevant clinical information
          from clinicians and facilities that have treated me and to furnish
          relevant clinical information to providers who will continue to treat
          me. I will indicate in writing any exceptions to this. Consent to
          Treatment Via Telehealth: I consent to participate in telemental
          health services. I understand that I have the right to refuse
          telemental health services and be informed of alternative services
          that may be available to me. If I request alternative services, I
          understand that Practice may not be able to provide those services,
          and that I may experience delays in service, the need to travel, or
          any other risks associated with not having services provided via
          telemental health, as well as risks associated with receiving
          telemental health services in an off-site location. I understand that
          telehealth may result in certain risks that are less likely to occur
          with in-person services, such as technology failure, need for
          specialized electronic security systems, and less visibility of
          non-verbal cues. Telehealth can also provide benefits not present with
          in-person services, such as creating greater flexibility for when and
          where services may be provided.
        </DialogContentText>
      </DialogContent>
      {!isConsentAccepted && (
        <DialogActions>
          <Button onClick={cancelConsentAccept}>Cancel</Button>
          <Button onClick={confirmConsentAccept} variant="contained">
            Accept and Continue
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConsentForm;

import React, { useEffect, useState } from "react";
import ChooseTherapist from "./ChooseTherapist";
import SelfAssessment from "./SelfAssessment";
import Courses from "./courses/Courses";
import Blogs from "./Blogs";
import { getOrgDetails } from "../../helper/api.helper";
import toast from "react-hot-toast";
import { useStoreState } from "easy-peasy";
import { Helmet } from "react-helmet";
import InstituteDetails from "./instituteDetails/InstituteDetails";

const Home = () => {
  const [data, setData] = useState();
  const user = useStoreState((state) => state.user);
  const orgCode =
  window.location.host.split(".").length > 2
    ? window.location.host.split(".")[0]
    : "default";
console.log(orgCode);
  useEffect(() => {
    getOrgDetails(orgCode)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          setData(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [user]);
  return (
    <div
      style={{
        padding:
          window.innerWidth > 600 ? "var(--space-lg)" : "var(--space-md)",
      }}
      className="wrapper"
    >
      <Helmet>
        <title>Home - DeTalks</title>
      </Helmet>

      <InstituteDetails />
      {orgCode === 'demo' ? <ChooseTherapist /> : null}
      <ChooseTherapist />
      {data?.selfAssessments && data?.selfAssessments.length ? (
        <SelfAssessment assessments={data?.selfAssessments} />
      ) : null}
      {data?.courses && data?.courses.length ? <Courses courses={data?.courses} /> : null}
      {orgCode === 'demo' ? <Blogs /> : null }
      {orgCode === 'demo' ? <Blogs /> : null }
      <style jsx>
        {`
          .wrapper {
            min-height: 90vh;
            max-width: 1150px;
            margin: auto;
          }
        `}
      </style>
    </div>
  );
};

export default Home;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionFields from "./accordionFields/AccordionFields";
import { useForm, useWatch } from "react-hook-form";
import { useStoreState } from "easy-peasy";
import {
  fetchClientDetailsFromDb,
  writeClientDetailsToDb,
} from "../profile.service";
import toast from "react-hot-toast";
import ErrorIcon from "@mui/icons-material/Error";

const AccordionSection = ({ fields, groupedFields, section, setLoading }) => {
  const [emptyMandatoryFields, setEmptyMandatoryFields] = useState(false);
  const { control, handleSubmit, reset, setValue, getValues } = useForm();
  const user = useStoreState((state) => state.user);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      if (user?.uid) {
        const clientDetails = await fetchClientDetailsFromDb(user.uid);
        // for (const [key, value] of Object.entries(clientDetails)) {
        // setValue(key, value);
        // }
        groupedFields[section].forEach((field) => {
          const fieldId = field.id;
          if (clientDetails.hasOwnProperty(fieldId)) {
            setValue(fieldId, clientDetails[fieldId]);
          }
        });
        setLoading(false);
      }
      setLoading(false);
    };

    fetchData();
  }, [user, setValue, groupedFields]);

  const watchedFields = useWatch({
    control,
    name: groupedFields[section].map((field) => field.id),
  });

  useEffect(() => {
    setLoading(true);
    if (user?.uid) {
      setEmptyMandatoryFields(false);
      groupedFields[section].forEach((field) => {
        if (field.mandatory) {
          const fieldValue = getValues(field.id);
          if (
            !fieldValue ||
            fieldValue === "" ||
            fieldValue === undefined ||
            fieldValue === null
          ) {
            setEmptyMandatoryFields(true);
            return;
          }
        }
      });
    }
    setLoading(false);
  }, [section, user, setValue, watchedFields, groupedFields]);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      await writeClientDetailsToDb(data, user?.uid);
      toast.success("Details saved successfully");
      //   reset();
    } catch (error) {
      toast.error("Error saving client details: ", error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion
          key={section}
          style={{ marginTop: "30px" }}
          // defaultExpanded={index === 0}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ width: "100%" }}>
              <h4 className="margin-top--xxxs" style={{ fontWeight: "600" }}>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </h4>

              {emptyMandatoryFields && (
                <p className="form-completion-status">
                  <ErrorIcon color="error" style={{ marginBottom: "-6px" }} />
                  &nbsp; Mandatory fields are empty!
                </p>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="fields-div">
              {groupedFields[section].map((field) => (
                <AccordionFields
                  key={field.id}
                  field={field}
                  control={control}
                />
              ))}
            </div>
          </AccordionDetails>
          {fields && fields.length > 0 && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: "-20px 0px 30px 18px" }}
            >
              Save
            </Button>
          )}
        </Accordion>
      </form>

      <style jsx>{`
        .form-completion-status {
          background-color: #fff4f1;
          padding: 10px 5px;
          border-radius: 5px;
        }
        .fields-div {
          max-width: 400px;
          margin-bottom: 20px;
        }
      `}</style>
    </>
  );
};

export default AccordionSection;

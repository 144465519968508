import React, { useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import DetailItem from "./DetailItem";
import { Button } from "@mui/material";

const PersonalDetails = ({ therapist }) => {

  const [showMore, setShowMore] = useState(false)

  const detailItems = [
    {
      icon: SchoolOutlinedIcon,
      label: "Qualification: ",
      value: therapist.qualification,
    },
    { icon: BadgeOutlinedIcon, label: "License: ", value: therapist.license },
    { icon: TimelineOutlinedIcon, label: "Experience: ", value: therapist.exp },
    {
      icon: StarBorderIcon,
      label: "Specialties: ",
      value: therapist.specialties,
    },
    {
      icon: PeopleAltOutlinedIcon,
      label: "Works with: ",
      value: therapist.worksWith,
    },
    { icon: LanguageIcon, label: "Languages: ", value: therapist.languages },
    {
      icon: InterestsOutlinedIcon,
      label: "Modalities: ",
      value: therapist.modalities,
    },
  ];

  return (
    <>
      <h4
        style={{
          borderBottom: "1px solid var(--border-gray)",
          paddingBottom: "10px"
        }}
      >
        About me
      </h4>
      <p>
        {showMore ? therapist.aboutMe : therapist.aboutMe.slice(0, 500) + "..."}
      </p>
      <Button
        onClick={() => {
          setShowMore(!showMore);
        }}
        style={{
          // justifyContent: "flex-start",
          paddingLeft: "0px"
        }}
      >
        {showMore ? "Show less" : "Show more"}{" "}
      </Button>
      <h4
        style={{
          borderBottom: "1px solid var(--border-gray)",
          paddingBottom: "10px"
        }}
      >
        Profile
      </h4>
      {detailItems.map((item, index) => (
        <DetailItem
          key={item.value}
          icon={item.icon}
          label={item.label}
          value={item.value}
        />
      ))}
    </>
  );
};

export default PersonalDetails;

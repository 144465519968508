import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { AppBar, Toolbar } from "@mui/material";
import { locations, therapists } from "../../helper/data.helper";
import PersonalDetails from "./PersonalDetails";
import MobileViewPopup from "./MobileViewPopup";
import ConsultationDetails from "./ConsultationDetails";
import AddressDetails from "./AddressDetails";
import ConsultationModes from "./ConsultationModes";
import ImageDetails from "./ImageDetails";

const TherapistDetailPage = () => {
  const { id } = useParams();

  const [selectedEventType, setSelectedEventType] = useState(
    "Individual Therapy - 45 min"
  );
  const [selectedLocation, setSelectedLocation] = useState(locations[0] + 0);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(-1);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [therapist, setTherapist] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleEventTypeChange = (event) => {
    setSelectedEventType(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleTimeClick = (timeSlot, index) => {
    setSelectedTime(timeSlot);
    setSelectedTimeIndex(index);
  };

  const handleBook = () => {
    setSelectedDate(new Date());
    setSelectedEventType("Individual Therapy - 45 min");
    setSelectedLocation(locations[0] + 0);
    setSelectedTime("");
    setSelectedTimeIndex();
  };

  const toggleDrawer = (open) => () => {
    handleBook();
    setIsDrawerOpen(open);
  };

  const formatDate = (date) => {
    const options = { month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const isMobile = useMediaQuery("(max-width:770px)");

  const handleDateClick = (date) => {
    // setSelectedTime(null);
    // setSelectedTimeIndex();
    setSelectedDate(new Date(date));
  };

  useEffect(() => {
    const foundTherapist = therapists.find(
      (therapist) => therapist.id === parseInt(id)
    );
    setTherapist(foundTherapist);
  }, [id]);

  if (!therapist) {
    return <Typography variant="h6">Therapist not found</Typography>;
  }

  return (
    <>
      <div className="top-div"></div>
      <div className="main-div flex-container">
        <div className="left-div">
          <div className="bottom-div flex-container">
            <div className="inner-1st-div flex-container">
              <ImageDetails therapist={therapist} />
            </div>
            <div className="inner-2nd-div margin-top--md">
              <PersonalDetails therapist={therapist} />
            </div>

            <div
              className="inner-3rd-div margin-top--sm"
              // style={{ paddingTop: "20px" }}
            >
              <ConsultationModes />
            </div>
            <div className="location-div inner-3rd-div margin-top--sm">
              <AddressDetails therapist={therapist} />
            </div>
          </div>
        </div>
        {window.innerWidth > 770 && (
          <div className="right-div">
            <ConsultationDetails
              selectedEventType={selectedEventType}
              handleEventTypeChange={handleEventTypeChange}
              selectedLocation={selectedLocation}
              handleLocationChange={handleLocationChange}
              therapist={therapist}
              selectedDate={selectedDate}
              handleDateClick={handleDateClick}
              selectedTimeIndex={selectedTimeIndex}
              handleTimeClick={handleTimeClick}
              handleBook={handleBook}
              formatDate={formatDate}
            />
          </div>
        )}
      </div>

      {isMobile && (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#fff",
            top: "auto",
            bottom: 0,
            padding: "0px 10px 0px 0px"
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <small>Next Available: {formatDate(new Date())} </small>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={toggleDrawer(true)}
              sx={{ textTransform: "none" }}
            >
              Check availability
            </Button>
          </Toolbar>
        </AppBar>
      )}

      <MobileViewPopup
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        selectedEventType={selectedEventType}
        handleEventTypeChange={handleEventTypeChange}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        therapist={therapist}
        selectedDate={selectedDate}
        handleDateClick={handleDateClick}
        selectedTimeIndex={selectedTimeIndex}
        handleTimeClick={handleTimeClick}
        formatDate={formatDate}
      />

      <style jsx>
        {`
          p,
          h3,
          h4,
          .main-div > span,
          div {
            color: black;
          }
          .right-div > span {
            color: unset;
          }

          .top-div {
            height: 100px;
            width: 100%;
            background-color: #e5efff;
            // margin-top: 50px;
          }
          .left-div {
            width: 65%;
          }
          .bottom-div {
            width: 100%;
            padding: 10px 10px 10px 20px;

            flex-direction: column;
            justify-content: center;
            align-items: start;
          }
          .inner-1st-div {
            width: 87%;
            justify-content: start;
            align-items: center;
            padding-left: 20px;
            margin-top: -20px;
          }

          .inner-2nd-div {
            width: 85%;
            padding: 0px 10px;
          }
          .inner-3rd-div {
            width: 87%;
            margin-left: 10px;
          }

          .location-div {
            justify-content: start;
            padding-bottom: 60px;
          }
          .right-div {
            width: 33%;
            margin-top: 20px;
            padding-bottom: 50px;
            padding-left: 10px;
          }
          @media only screen and (min-width: 1300px) {
            .bottom-div {
              margin-left: 10%;
            }
          }

          @media only screen and (max-width: 1025px) {
            .inner-1st-div {
              width: 100%;
            }
            .inner-3rd-div {
              width: 100%;
            }
          }
          @media only screen and (max-width: 815px) {
            .main-div {
              flex-direction: column;
            }
            .right-div {
              margin-left: 1%;
            }
            .left-div {
              width: 90%;
            }
          }

          @media only screen and (max-width: 600px) {
            .left-div {
              width: 100%;
            }
            .bottom-div {
              padding: 5px;
            }
            .right-div {
              width: 100%;
            }
            .inner-3rd-div {
              margin-left: 10px;
            }
          }

          @media only screen and (max-width: 350px) {
            .right-div {
              padding-left: 4px;
            }
          }
        `}
      </style>
    </>
  );
};

export default TherapistDetailPage;

import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const AccordionFields = ({ field, control }) => {
  return (
    <Controller
      key={field.id}
      name={field.id}
      control={control}
      defaultValue=""
      rules={{
        required: field.mandatory ? `Please fill the ${field.name}` : false,
        ...(field.type === 4 && {
          pattern: {
            value: /^(?:\+91)?\d{10}$/,
            message: "Please enter a valid phone number",
          },
        }),
        ...(field.type === 5 && {
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address",
          },
        }),
        ...(field.ends_with && {
          validate: (val) =>
            val.endsWith(field.ends_with)
              ? true
              : `Please enter organisation email ending with ${field.ends_with}`,
        }),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {field.type === 0 ? (
            <TextField
              type="text"
              label={field.name}
              placeholder={field.name}
              variant="outlined"
              fullWidth
              margin="normal"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ""}
              size="small"
              multiline={!!field.rows}
              rows={field.rows}
              required={field.mandatory}
            />
          ) : field.type === 1 ? (
            <TextField
              type="number"
              label={field.name}
              placeholder={field.placeholder}
              variant="outlined"
              fullWidth
              margin="normal"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ""}
              size="small"
              required={field.mandatory}
            />
          ) : field.type === 2 ? (
            <TextField
              required={field.mandatory}
              select
              label={field.name}
              variant="outlined"
              fullWidth
              margin="normal"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ""}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: { maxHeight: 400 },
                  },
                },
              }}
              size="small"
            >
              {field.values.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          ) : field.type === 3 ? (
            <TextField
              type="date"
              label={field.name}
              placeholder={field.placeholder}
              variant="outlined"
              fullWidth
              margin="normal"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ""}
              size="small"
              required={field.mandatory}

              // InputLabelProps={{ shrink: true }}
            />
          ) : field.type === 4 ? (
            <TextField
              type="tel"
              label={field.name}
              placeholder={field.placeholder}
              variant="outlined"
              fullWidth
              margin="normal"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ""}
              size="small"
              required={field.mandatory}
            />
          ) : field.type === 5 ? (
            <TextField
              type="email"
              label={field.name}
              placeholder={field.placeholder}
              variant="outlined"
              fullWidth
              margin="normal"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : ""}
              size="small"
              required={field.mandatory}
            />
          ) : null}
        </>
      )}
    />
  );
};

export default AccordionFields;
